import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DesignSystem from "../design-system/vue.js";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

// const app = createApp(App);

// // Design System component registration
// DesignSystem.register(app);

// app
//   .use(store)
//   .use(router)
//   .mount("#app");

let app;
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App);
    // Design System component registration
    DesignSystem.register(app);

    app.use(store).use(router).mount("#app");
  }
});