<template>
  <div id="app">
    <List el="xsm" class="login">
      <img src="/C2C_logo_v2_no_bg.png" alt="c2c logo" id="logo">
      <List class="inputGroup">
        <input type="text" v-model="login.username" placeholder="Enter email">
        <input type="password" v-model="login.password" placeholder="Password">
      </List>
      <List el="xxsm">
        <Btn @click="$store.commit('emailSignIn', login.username, login.password)">Sign in</Btn>
        <!-- <Btn @click="$store.commit('createEmailSignIn', login.username, login.password)">Create Sign in</Btn> -->
        <Btn class="gSignIn" @click="$store.commit('googleSignIn')">Google</Btn>
      </List>
      <Link to="/" class="link">Forgot Password</Link>
      <div v-if="login.error != ''" style="color: red">
        {{ login.error }}
      </div>
      <Text el="p" style="margin-top:45px;text-align:center;">© 2021-2022. C2C LLC</Text>
  </List>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Login',
  components: {
    // HelloWorld
  },
  data() {
    return {
      login: {
        username: "",
        password: "",
        error: "",
      },
    };
  },
}
</script>
<style lang="scss" scoped>
input{
  padding:0 10px;
  height:45px;
  border:0;
  font-size: 16px;
  font-family:inherit;
}
.inputGroup{
  --gap:1px;
  background-color:#ced4da;
  border-radius:5px;
  overflow:hidden;
  border:1px solid #ced4da;
}
body {
  margin: 0;
  padding: 0;
}
.login {
  max-width: 364px;
  min-width:300px;
  margin-bottom: 60px;
  // --bg-color: #fff;
  border-radius: 16px;
  padding: 15px;
  --gap: 24px;
  width: 100%;
  z-index: 2;
  align-self: center;
}
#logo{
  height:102px !important;
  width:auto !important;
  margin:auto;
}
.microsoft-sign-in {
  --bg-color: #000;
  --hover-bg-color: #222;
  --color: #fff;
  font-weight: var(--font-medium);
  grid-gap: 8px;
  --height: 40px;
  /* border:1px solid hsla(0,0%,100%,1); */
  /* box-shadow:0 0 0 3px hsla(0,0%,100%,0.7); */
}
.microsoft-sign-in img {
  height: 20px;
}
#app {
  height: 100%;
  width: 100%;
  position: fixed;
  display: grid;
  align-items: center;
    justify-content: center;
  background-color: #f1f1f1;
  overflow: auto;
}
#logo {
  width: 100%;
  height: 50px;
}
.gSignIn {
  --bg-color: #fff;
    --hover-bg-color: #fff;
    --color: #444;
    // font-weight: var(--font-bold);
  grid-gap: 8px;
  --height: 40px;
  background-image: url(../assets/icons/google.webp);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: 0.3s;
  box-shadow: 0 0 0 3px transparent;
}
.gSignIn:hover{
  box-shadow: 0 0 0 3px;
}
.link{
  margin:auto;
}
@media (max-width: 800px) {}
</style>
