import { createStore } from 'vuex'
import router from "../router";
import {db} from "../firebase";

import {
  getAuth,
  signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore/lite";

export default createStore({
  state: {
    user: {
      profileImg: null,
      displayName: null,
      firstname:null,
      lastname:null,
      email: null,
      exists: null,
      photoURL:null,
      lastSignIn:null,
      createdOn:null,
      uid:null,
    },
    groups: {
      admin: ["wesley@burkedesigns.biz",'wesleyburkew2@gmail.com'],
    },
  },
  getters: {
    isAdmin: (state) => {
      return state.groups.admin.includes(state.user.email);
    },
  },
  mutations: {
    // createEmailSignIn(state, email, password) {
    //   let x = this;
    //   const auth = getAuth();
    //   createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in 
    //     const user = userCredential.user;
    //     console.log('--- Google Sign In Data ---',user);
    //       // x.commit("loadCurrentUserData");
    //     // ...
    //   })
    //   .catch((error) => {
    //     const errorMessage = error.message;
    //       const email = error.email;
    //       console.log(email, errorMessage);
    //     // ..
    //   });
      
    // },
    emailSignIn(state, email, password) {
      let x = this;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log('--- Google Sign In Data ---',user);
          x.commit("loadCurrentUserData");
          alert('signed in!');
          // router.push("/dashboard");
          // ...
        })
        .catch((error) => {
          const errorMessage = error.message;
          const email = error.email;
          console.log(email, errorMessage);
        });
      
    },
    googleSignIn(state) {
      let x = this;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result.user;
          console.log('--- Google Sign In Data ---',user);
          x.commit("loadCurrentUserData");
          router.push("/dashboard");
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorMessage = error.message;
          const email = error.email;
          console.log(email, errorMessage);
        });
    },
    async loadCurrentUserData(state){
      const auth = getAuth();
      if(auth.currentUser != null){
        state.user.displayName = auth.currentUser.displayName;
        state.user.email = auth.currentUser.email;
        state.user.photoURL = auth.currentUser.photoURL;

        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let data = docSnap.data();
          console.log("Google sign in data:", docSnap.data());
          state.user = docSnap.data();
          state.user.exists = true;
          state.user.uid = auth.currentUser.uid;
        } else {
          // show signup menu
          state.user.exists = false;
          console.log("No such user!");
        }
      }else{
        console.log('Current user not found.');
      }
      
    },
    importUserData(state, data = null){
      if(data != null){
        state.user = data;
      }else{
        console.log('Unable to import user data.');
      }
    },
    resetUser(state){
      state.user.profileImg =  null;
      state.user.displayName =  null;
      state.user.firstname = null;
      state.user.lastname = null;
      state.user.email =  null;
      state.user.exists =  null;
      state.user.paid = false;
      state.user.photoURL = null;
      state.user.lastSignIn = null;
      state.user.createdOn = null;
      state.user.uid = null;
    },
    signOut() {
      let x = this;
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          x.commit("resetUser");
          router.push("/");
        })
        .catch(() => {
          // An error happened.
        });
    },
  },
  actions: {
  },
  modules: {
  }
})
